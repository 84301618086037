import ResponsiveImage from '@/lib/components/images/responsive-image';
import { ImageField } from '@/lib/state';

export default function ImageBannerStatic({
  image,
  editMode = false,
}: {
  image: ImageField;
  editMode?: boolean;
}) {
  return (
    <div className="h-full w-full" {...(editMode && { onDragStart: (e) => e.preventDefault() })}>
      <ResponsiveImage
        image={image}
        options={{
          loading: image.lazy ? 'lazy' : 'eager',
          priority: !image.lazy,
        }}
      />
    </div>
  );
}
