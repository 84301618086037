'use client';

import { PropsWithChildren } from 'react';

import { ImageBannerConfig } from '@/lib/state';
import { SectionProps } from '@/lib/sections';

import Container from '../../components/container.component';
import ImageBanner from '../../components/image-banner/image-banner.component';

import SectionElement from '../section-element.component';

export default function ImageBannerSection({
  editMode,
  section,
  globalConfig,
  SectionElementWrapper,
}: PropsWithChildren & SectionProps<ImageBannerConfig>) {
  return (
    <Container containerWidth={section.configuration.containerWidth}>
      <ImageBanner
        editMode={editMode}
        overlayOpacity={section.configuration?.overlayOpacity}
        image={section.configuration?.image}
        height={section.configuration.height}
      >
        {section.configuration.elements.map((element, index) => (
          <SectionElement
            key={element.id}
            element={element}
            elementIndex={index}
            section={section}
            baseClassName="rigel-image-banner"
            editMode={editMode}
            globalConfig={globalConfig}
            SectionElementWrapper={SectionElementWrapper}
          />
        ))}
      </ImageBanner>
    </Container>
  );
}
