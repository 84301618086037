'use client';

import { FunctionComponent, PropsWithChildren } from 'react';

import { SectionProps } from '@/lib/sections';
import { GenericConfig, CarouselConfig } from '@/lib/state';

import Slider from '@rigel/components/carousels/slider.component';
import Slide from './slide.component';
import { SectionItemWrapperProps } from '@/components/section-items/section-item-wrapper.component';
import { twMerge } from 'tailwind-merge';

export default function ImageCarouselSlideText({
  editMode,
  section,
  globalConfig,
  SectionElementWrapper,
  SectionItemWrapper,
}: PropsWithChildren &
  SectionProps<CarouselConfig> & {
    SectionItemWrapper?: FunctionComponent<SectionItemWrapperProps>;
  }) {
  const hasItemWithElements = !!section.configuration.items.find((item) => {
    return item.elements.length > 0;
  });

  const height = section.configuration.height;

  return (
    <>
      {section.configuration.items && (
        <div
          className={twMerge(
            'rigel-image-carousel-slide-text relative mx-auto',
            height === 'small' && 'max-h-[50svh] @md:max-h-none h-64 @sm:h-[400px]',
            (height === 'medium' || !height) && 'max-h-[70svh] @md:max-h-none h-96 @sm:h-[600px]',
            height === 'large' && 'max-h-[90svh] @md:max-h-none h-[40rem] @sm:h-[800px]'
          )}
        >
          <Slider
            autoPlay
            delay={section.configuration.delay}
            className="relative overflow-hidden h-full text-white z-10"
          >
            {section.configuration.items.map((item, slideIndex) => {
              const genericItem = item as GenericConfig;
              return (
                <Slide
                  key={item.id}
                  SectionItemWrapper={SectionItemWrapper}
                  item={genericItem}
                  slideIndex={slideIndex}
                  hasItemWithElements={hasItemWithElements}
                  section={section}
                  editMode={editMode}
                  globalConfig={globalConfig}
                  SectionElementWrapper={SectionElementWrapper}
                />
              );
            })}
          </Slider>
        </div>
      )}

      {editMode && !section.configuration.items && <span>Need to add some config items</span>}
    </>
  );
}
