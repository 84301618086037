import { twMerge } from 'tailwind-merge';

import RichTextElement from '@/components/section-elements/rich-text-element.component';

import Heading from '@rigel/components/heading.component';

import { buttonClasses } from '../components/buttons/button.component';
import { SectionElementProps } from '@/lib/sections';
import { ElementType } from '@/lib/state';
import Image from 'next/image';
import { CSSProperties } from 'react';
import SocialElement from './social-element.component';
import LinkButton from '../components/buttons/link-button.component';
import { colorValue } from '@/utils/color-style-from-config';

/**
 * Renders elements of various types that are added to sections on the Rigel theme.
 *
 * **Note:** if a section supports `searchForm` type elements, ensure the `globalConfig`
 * is passed down to default to `globalConfig.searchForm` for configuration.
 */
export default function SectionElement({
  section,
  element,
  elementIndex,
  baseClassName,
  editMode,
  itemIdChain,
  SectionElementWrapper,
  globalConfig,
}: SectionElementProps) {
  function renderContent() {
    switch (element.type) {
      case ElementType.Heading:
        return (
          <Heading
            level={element.level}
            className={`mb-0 relative[&_input]:text-center ${baseClassName}__heading`}
            style={{
              ...(element.color && { color: colorValue(element.color) }),
            }}
          >
            <RichTextElement
              section={section}
              elementIndex={elementIndex}
              itemIdChain={itemIdChain}
            />
          </Heading>
        );

      case ElementType.Link:
        return editMode ? (
          <div className="flex justify-center">
            <span
              className={twMerge(
                buttonClasses(element.buttonVariant, element.buttonSize),
                `${baseClassName}__link`
              )}
            >
              <RichTextElement
                section={section}
                elementIndex={elementIndex}
                itemIdChain={itemIdChain}
              />
            </span>
          </div>
        ) : (
          <LinkButton
            href={element.url || '/'}
            className={`${baseClassName}__link`}
            variant={element.buttonVariant}
            size={element.buttonSize}
            {...(element.target && { target: element.target })}
          >
            <RichTextElement
              section={section}
              elementIndex={elementIndex}
              itemIdChain={itemIdChain}
            />
          </LinkButton>
        );
      case ElementType.Social:
        return <SocialElement element={element} editMode={editMode} />;
      case ElementType.SearchForm:
        /* Not currently supported in Rigel */
        /* Use globalConfig search form when supported */
        return null;

      case ElementType.Image:
        return (
          <Image
            src={element.image?.src || ''}
            alt={element.image?.alt || ''}
            width={element.image?.width || 200}
            height={element.image?.height || 200}
            style={{
              height: element.image?.height || 200,
              width: element.image?.width || 200,
              objectFit: (element.image?.objectFit as CSSProperties['objectFit']) || 'contain',
            }}
          />
        );

      default:
        return (
          <div
            className={`${baseClassName}__text`}
            style={{
              ...(element.color && { color: colorValue(element.color) }),
            }}
          >
            <RichTextElement
              section={section}
              elementIndex={elementIndex}
              itemIdChain={itemIdChain}
            />
          </div>
        );
    }
  }

  return (
    <div
      style={{
        marginTop: `${element.marginTop || 0}px`,
        marginBottom: `${element.marginBottom || 0}px`,
      }}
    >
      {SectionElementWrapper ? (
        <SectionElementWrapper
          editMode={editMode}
          element={element}
          elementIndex={elementIndex}
          section={section}
          itemIdChain={itemIdChain}
        >
          {renderContent()}
        </SectionElementWrapper>
      ) : (
        renderContent()
      )}
    </div>
  );
}
