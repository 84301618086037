'use client';

import { useForm } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';

import { ButtonSize, CustomFormConfig } from '@/lib/state';
import { SectionProps } from '@/lib/sections';
import { CustomFormParams, FormElement as FormElementType } from '@/lib/models';

import Button from '@rigel/components/buttons/button.component';

import Container from '../../components/container.component';
import SectionElement from '../section-element.component';
import FormElement from './components/form-element.component';
import SendingMessage from '../../components/forms/sending-message.component';
import MessageSent from '../../components/forms/message-sent.component';
import MarketingPreferences from '../../components/forms/marketing-preferences.component';
import { SerializableAgency } from '@/serializers/agency';
import useCustomForm from '@/lib/hooks/use-custom-form';
import FormCard from './form-card/form-card.component';
import { FunctionComponent } from 'react';
import { SectionItemWrapperProps } from '@/components/section-items/section-item-wrapper.component';

export default function CustomForm({
  editMode,
  section,
  SectionElementWrapper,
  SectionItemWrapper,
  agency,
}: SectionProps<CustomFormConfig> & {
  agency: SerializableAgency;
  SectionItemWrapper?: FunctionComponent<SectionItemWrapperProps>;
}) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CustomFormParams>();

  const { loadingForms, status, forms, onSubmit, selectedForm } = useCustomForm({ section });

  const variant = section.configuration.variant;

  if (status === 'loading')
    return (
      <div className="[&_p]:text-white">
        <SendingMessage />
      </div>
    );

  if (status === 'success')
    return (
      <div className="[&_p]:text-white">
        <MessageSent />
      </div>
    );

  return (
    <Container containerWidth={section.configuration.containerWidth}>
      <div className={twMerge('grid grid-cols-1', variant === 'form-card' && '@md:grid-cols-3')}>
        {variant === 'form-card' && !!section.configuration.items && (
          <FormCard
            editMode={editMode}
            section={section}
            SectionElementWrapper={SectionElementWrapper}
            SectionItemWrapper={SectionItemWrapper}
            className="h-auto @md:h-full"
          />
        )}
        <div
          className={twMerge(
            'bg-grey-alternative p-10 @lg:p-20',
            variant === 'form-card' && '@md:col-span-2'
          )}
        >
          {!!section.configuration.elements.length && (
            <div className="w-full mb-8 flex flex-col items-start">
              {section.configuration.elements.map((element, index) => (
                <SectionElement
                  key={element.id}
                  element={element}
                  elementIndex={index}
                  section={section}
                  baseClassName="capella-contact-form"
                  editMode={editMode}
                  SectionElementWrapper={SectionElementWrapper}
                />
              ))}
            </div>
          )}
          <form
            onSubmit={handleSubmit(onSubmit)}
            className={twMerge(editMode && 'pointer-events-none')}
            data-form-name={selectedForm?.form_name}
          >
            <div className="w-full grid grid-cols-1 @sm:grid-cols-2 gap-y-4 gap-x-6">
              {loadingForms &&
                !forms.length &&
                Array.from({ length: 4 }, (_, i) => i).map((el) => (
                  <div key={el} className="h-10 w-full bg-slate-200 animate-pulse mb-4" />
                ))}
              {selectedForm?.form_elements
                .sort(
                  (a: FormElementType, b: FormElementType) => a.field_position - b.field_position
                )
                .map((element, index) => {
                  const formElement = section.configuration?.formElements?.[index];
                  return (
                    <div
                      key={element.field_name}
                      className={twMerge(
                        formElement &&
                          'fullWidth' in formElement &&
                          formElement.fullWidth &&
                          '@sm:col-span-2'
                      )}
                    >
                      <FormElement register={register} element={element} formId={section.id} />
                    </div>
                  );
                })}
            </div>
            <div className="flex justify-center w-full">
              <MarketingPreferences
                className="mb-4"
                registerValidation={register}
                agency={agency}
                errors={errors}
              >
                <Button type="submit" className="w-full @sm:w-fit px-20" size={ButtonSize.Small}>
                  {section.configuration.link?.html || 'Submit'}
                </Button>
              </MarketingPreferences>
            </div>
          </form>
        </div>
      </div>
    </Container>
  );
}
