import { SectionElement, SocialElement as ISocialElement } from '@/lib/state';
import Phone from '../svgs/phone';
import { FaFacebook, FaInstagram, FaLinkedinIn, FaPinterest, FaXTwitter } from 'react-icons/fa6';
import Email from '../svgs/email';
import Link from 'next/link';
import { twMerge } from 'tailwind-merge';

const ICON_CLASSES = 'inline-block h-6 w-6 stroke-primary-text text-primary-text';

export default function SocialElement({
  element,
  editMode,
}: {
  element: SectionElement;
  editMode?: boolean;
}) {
  if (!element.social) return null;

  function renderIcon(socialType: string) {
    switch (socialType) {
      case 'phone':
        return <Phone className={ICON_CLASSES} />;
      case 'instagram':
        return <FaInstagram className={ICON_CLASSES} />;
      case 'pinterest':
        return <FaPinterest className={ICON_CLASSES} />;
      case 'facebook':
        return <FaFacebook className={ICON_CLASSES} />;
      case 'linkedIn':
        return <FaLinkedinIn className={ICON_CLASSES} />;
      case 'x':
        return <FaXTwitter className={ICON_CLASSES} />;
      default:
        return <Email className={ICON_CLASSES} />;
    }
  }

  return (
    <div className="flex gap-4">
      {Object.keys(element.social).map((socialType: string) => {
        const icon = element.social?.[socialType as keyof ISocialElement];

        if (!icon?.url) return null;

        return (
          <Link
            key={socialType}
            href={icon.url}
            aria-label={`Icon link to ${socialType}`}
            className={twMerge(editMode && 'pointer-events-none')}
            target={icon.target}
          >
            {renderIcon(socialType)}
          </Link>
        );
      })}
    </div>
  );
}
