'use client';

import { PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';
import Image from 'next/image';

import { ImageWithTextConfig } from '@/lib/state';
import { SectionProps } from '@/lib/sections';

import SectionElement from '../section-element.component';
import Container from '../../components/container.component';
import {
  imageWithTextElementContainerClasses,
  imageWithTextImageContainerClasses,
} from '@homeflow/next/sections/image-with-text-classes';

export default function ImageWithText({
  editMode,
  section,
  SectionElementWrapper,
}: PropsWithChildren & SectionProps<ImageWithTextConfig>) {
  return (
    <Container containerWidth={section.configuration.containerWidth}>
      <div
        className={twMerge(
          'image-with-text flex flex-col-reverse h-full gap-8 @lg:gap-0 @lg:flex-row @lg:bg-grey-alternative',
          section.configuration.image?.position === 'right' && '@lg:flex-row-reverse',
          editMode && 'group/section'
        )}
      >
        <div className={twMerge('basis-1/2', imageWithTextImageContainerClasses(section))}>
          {section.configuration?.image?.src && (
            <Image
              src={section.configuration.image.src}
              alt={section.configuration.image.alt}
              priority={!section.configuration.image.lazy}
              width={1100}
              height={500}
              className="h-full"
              // TS was complaining about the undefined case which will never be an issue due to the fallback.
              // Attempted solutions were becoming more convoluted
              // @ts-ignore
              style={{ objectFit: section.configuration?.image?.type || 'cover' }}
            />
          )}
        </div>

        <div
          className={twMerge(
            'image-with-text__content basis-1/2 flex flex-col justify-center z-10 w-full gap-5 items-start @lg:px-24 @lg:py-8',
            imageWithTextElementContainerClasses(section)
          )}
        >
          {section.configuration.elements.map((element, index) => (
            <SectionElement
              key={element.id}
              element={element}
              elementIndex={index}
              section={section}
              baseClassName="rigel-image-with-text"
              editMode={editMode}
              SectionElementWrapper={SectionElementWrapper}
            />
          ))}
        </div>
      </div>
    </Container>
  );
}
