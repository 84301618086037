import { ConfigSettingType } from '@/lib/sections';
import { ElementType, GenericConfig } from '@/lib/state';
import { v4 as uuid } from 'uuid';

export const initialFormCard = (): GenericConfig => ({
  id: uuid(),
  image: {
    src: '/images/hero-default-0.jpg',
    alt: 'House at twilight',
  },
  elements: [
    { id: uuid(), type: ElementType.Heading, html: 'Title', level: 3 },
    { id: uuid(), type: ElementType.Text, html: 'Description' },
    {
      id: uuid(),
      type: ElementType.Social,
      social: {
        email: { url: '/', target: '_self' },
        phone: { url: '/', target: '_self' },
        facebook: { url: '', target: '_self' },
        instagram: { url: '', target: '_self' },
        x: { url: '', target: '_self' },
        linkedIn: { url: '', target: '_self' },
        pinterest: { url: '', target: '_self' },
      },
    },
  ],
});

export const generateSettings = () => {
  return [
    {
      label: 'Image',
      type: ConfigSettingType.Image,
      field: `image`,
    },
  ];
};
